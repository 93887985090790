define("ember-svg-jar/inlined/ibm/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 13l.705-.705L3.915 8.5H14v-1H3.915l3.79-3.795L7 3 2 8l5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16",
      "fill": "currentColor"
    }
  };
});
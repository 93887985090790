define("ember-svg-jar/inlined/ibm/image-reference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 20v2h4.586L2 28.586 3.414 30 10 23.414V28h2v-8H4zm15-6a3 3 0 10-3-3 3 3 0 003 3zm0-4a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M26 4H6a2 2 0 00-2 2v10h2V6h20v15.17l-3.59-3.59a2 2 0 00-2.82 0L18 19.17 11.83 13l-1.414 1.415L14 18l2.59 2.59a2 2 0 002.82 0L21 19l5 5v2H16v2h10a2 2 0 002-2V6a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});